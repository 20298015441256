import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '@ids-alpha/responsive-grid';
import '../../../stylesheets/responsiveGridStyles.scss';
import '../../../stylesheets/main.scss';
import '../../../stylesheets/footer.scss';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import privacyoptions from '../../../assets/images/IM/Footer/privacyoptions.svg';
import INTUIT_LOGO_WHITE from '../../../assets/images/IM/Footer/INTUIT_LOGO_WHITE.svg';
import {
  getSessionStorage,
  setSessionStorage,
  removeSessionStorage,
  convertToSnakeCase,
} from '../Util/CommonUtils.jsx';
import {
  CPRA_Footer_Text,
  CPRA_Location_Country_Value,
  CPRA_Location_State_Value,
} from '../../../assets/pagedata/constant';
// eslint-disable-next-line require-jsdoc
const FooterLink = () => {
  const [inputValueSearch, setInputValueSearch] = useState('');
  const [userLocation, setUserLocation] = useState('');

  const mainReducer = useSelector((state) => state.mainReducer);

  const CpraFunctionalityAvailable =
    mainReducer &&
    mainReducer.clientConfigs &&
    mainReducer.clientConfigs.intuitmarketFlagsisCpraEnabled &&
    mainReducer &&
    mainReducer.clientConfigs &&
    mainReducer.clientConfigs?.intuitmarketFlagsisCpraEnabled.toLowerCase() === 'true';

  const handleSubmit = (event) => {
    const searchParameters = inputValueSearch;
    const footerSearchQuery = {
      searchText: searchParameters,
      currentUrl: window.location.href,
    };
    const savedSearchQuery = getSessionStorage('IMSearchParams');
    savedSearchQuery && removeSessionStorage('IMSearchParams');

    setSessionStorage('footerSearchQuery', JSON.stringify(footerSearchQuery));
    const trackInfo = {
      currentUrl: window.location.href,
      currentPageTitle: document.title,
      userAgt: navigator.userAgent.toLowerCase(),
      language: navigator.language,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
    };
    setSessionStorage('trackInfo', JSON.stringify(trackInfo));
    console.log('clicked search');
    event.preventDefault();
    window.open('/search', '_self');
  };

  const manageCookie = () => {
    if (typeof OneTrust !== 'undefined' && typeof OneTrust.ToggleInfoDisplay === 'function') {
      OneTrust.ToggleInfoDisplay();
    } else {
      intuit_gdpr.showCookiePreference();
    }
  };

  const updateInputValue = (event) => {
    setInputValueSearch(event.target.value);
  };

  const showCPRAModal = () => {
    if (typeof OneTrust !== 'undefined' && typeof OneTrust.ToggleInfoDisplay === 'function') {
      OneTrust.ToggleInfoDisplay();
    } else {
      intuit_gdpr.showCookiePreference();
    }
  };

  useEffect(() => {
    // get for users location can be done using OneTrust’s function
    if(window.OneTrust && typeof window.OneTrust.getGeolocationData === 'function'){ 
    const cpraGeoLocation = window.OneTrust?.getGeolocationData()
    setUserLocation(cpraGeoLocation ? cpraGeoLocation : '');}
    else{
      setUserLocation('');
    }
  }, [window.OneTrust, CpraFunctionalityAvailable]);

  return (
    <div className="container-center-lg footer-container">
      <div className="other-product">
        <div className="row gutter">
          <div className="col-xs-12 col-sm-12 col-lg-3">
            <div className="row gutter">
              <div className="col-xs-12 col-sm-12 col-lg-12 margin-data">
                <div className="im-name">
                  <span>QuickBooks</span>
                  <span> Checks & Supplies</span>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4 col-lg-12">
                <div className="search-type">
                  <form role="search" id="IMFooterSearchForm" onSubmit={handleSubmit}>
                    <div>
                      <input
                        aria-label="Search"
                        type="text"
                        onChange={updateInputValue}
                        value={inputValueSearch}
                        className="search-query"
                        placeholder="Search this site"
                        data-di-id="#txtSearchFt"
                      />
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        aria-label="Submit"
                        id="IMFooterSearchFormSubmit"
                        data-wa-link="search"
                        data-di-id="#IMFooterSearchFormSubmit"
                      >
                        <span
                          className="add-on"
                          role="button"
                          aria-label="search"
                          data-wa-link={`search ${inputValueSearch}`}
                          data-ui-object="form_field"
                          data-ui-object-details="Search this site"
                          data-refer-ui-access-point={`search_${convertToSnakeCase(
                            inputValueSearch,
                          )}`}
                          onKeyDown={(event) => {
                            event.key === 'Enter' && handleSubmit(event);
                          }}
                          onClick={handleSubmit}
                          tabIndex={0}
                        >
                          {' '}
                          <i className="icon" />{' '}
                        </span>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-xs-12 col-sm-8 col-lg-12">
                <div>
                  <span className="ft-cust-service">Customer Service</span>
                  <span className="ft-cust-service ft-cust-style">Phone:&nbsp;(866) 570-3842 </span>
                  <span className="ft-cust-service ft-cust-style">
                    Mon - Fri,&nbsp;9am - 8pm ET
                  </span>
                </div>
              </div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="find-order responsive-data"
                href="/orderstatus/lookup"
                data-wa-link="findorder"
                data-ui-object="link"
                data-refer-ui-access-point="find_an_order"
                id="reorderChecksWidget1"
              >
                Find an Order
              </a>
              <a
                className="term-service responsive-data"
                href="https://www.intuit.com/legal/terms/en-us/quickbooks/checks-supplies/"
                data-wa-link="termsofservice"
                data-ui-object="link"
                data-refer-ui-access-point="terms_of_service"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-3">
            <div className="responsive-data dropdown">
              <button type="submit" className="im-name dropbtn">
                Resource Center
                <i className="icon-dropdown" />
              </button>
            </div>
            <div className="dropdown-content">
              <Link to="/help" target="_blank">
                Help
              </Link>
              <Link to="/help/termsofservice" target="_blank" style={{ display: 'none' }}>
                Articles
              </Link>
              <Link to="/shipping-handling-faq" target="_blank">
                Shipping &amp; Handling FAQ
              </Link>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-6">
            <div className="im-name responsive-data dropdown">
              <button type="submit" className="im-name dropbtn">
                Other Intuit Products
                <i className="icon-dropdown" />
              </button>
            </div>
            <ul className="product-category drop-down-data">
              <li className="drop-down-content">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://quickbooks.intuit.com/online/"
                >
                  QuickBooks Online
                </a>
              </li>
              {/* Removing QuickBooks Desktop Pro - UBERPSG-15328 */}
              {/* <li className="drop-down-content">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://quickbooks.intuit.com/desktop/pro/"
                >
                  QuickBooks Desktop Pro
                </a>
              </li> */}
              <li className="drop-down-content">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://quickbooks.intuit.com/desktop/enterprise/"
                >
                  QuickBooks Desktop Enterprise
                </a>
              </li>
              {/* removing quickbooks for mac link - UBERPSG-15328 */}
              {/* <li className="drop-down-content">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://quickbooks.intuit.com/desktop/enterprise/"
                >
                  QuickBooks for Mac
                </a>
              </li> */}
              <li className="clearspace drop-down-content">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://quickbooks.intuit.com/solopreneur/"
                >
                  QuickBooks Solopreneur
                </a>
              </li>
              <li className="drop-down-content">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://quickbooks.intuit.com/payments/"
                >
                  QuickBooks Payments
                </a>
              </li>
              <li className="drop-down-content">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://quickbooks.intuit.com/payroll/"
                >
                  QuickBooks Payroll
                </a>
              </li>
              <li className="drop-down-content">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://quickbooks.intuit.com/time-tracking/"
                >
                  QuickBooks Time
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="cookie-info">
        <div className="manage-cookie" />
      </div>
      <div className="intuit-info">
        <div className="row gutter" style={{ marginTop: '20px' }}>
          <div className="col-xs-12 col-sm-4 col-lg-3">
            <div className="ctext">
              <a
                rel="noopener noreferrer"
                href="https://www.intuit.com/"
                className="oi-footer-global-logo"
                data-wa-link="ftr-global-intuitbrand"
                data-ui-object="link"
                data-refer-ui-access-point="ftr_global_intuitbrand_logo"
                target="_blank"
                aria-label="Intuit Powering Prosperity"
              >
                <img
                  src={INTUIT_LOGO_WHITE}
                  alt="intuit"
                  height="45px"
                  width="226.4px"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-sm-8 col-lg-9">
            <div className="">
              <section className="ccontainer">
                <div className="ctext">
                  <p className="oi-footer-global-brands">
                    <a
                      rel="noopener noreferrer"
                      className="oi-footer-global-brandsLink qb-logo"
                      href="https://quickbooks.intuit.com/"
                      data-wa-link="ftr-quickbooks-qb"
                      data-ui-object="link"
                      data-refer-ui-access-point="ftr_quickbooks_logo"
                      aria-label="Quickbooks"
                      target="_blank"
                    >
                      <span className="products-icon-quickbooks oi-footer-image" />
                    </a>
                    <a
                      rel="noopener noreferrer"
                      className="oi-footer-global-brandsLink tt-logo"
                      href="https://turbotax.intuit.com/"
                      data-wa-link="ftr-quickbooks-turbotax"
                      data-ui-object="link"
                      data-refer-ui-access-point="ftr_turbotax_logo"
                      target="_blank"
                      aria-label="TurboTax"
                    >
                      <span className="products-icon-turbotax oi-footer-image" />
                    </a>
                    <a
                      rel="noopener noreferrer"
                      className="oi-footer-global-brandsLink pro-logo"
                      href="https://accountants.intuit.com/"
                      data-wa-link="ftr-quickbooks-proconnect"
                      data-ui-object="link"
                      data-refer-ui-access-point="ftr_proconnect_logo"
                      target="_blank"
                      aria-label="ProConnect"
                    >
                      <span className="products-icon-proconnect oi-footer-image" />
                    </a>
                    <a
                      rel="noopener noreferrer"
                      className="oi-footer-global-brandsLink ck-logo"
                      href="https://www.creditkarma.com"
                      data-wa-link="ftr-quickbooks-credit-karma"
                      data-ui-object="link"
                      data-refer-ui-access-point="ftr_credit_karma_logo"
                      target="_blank"
                      aria-label="CreditKarma"
                    >
                      <span className="products-icon-credit-karma oi-footer-image" />
                    </a>
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className="row gutter ctext">
          <div className="col-xs-12 col-sm-4 col-lg-3">
            <div className="footer-info-margin">
              <div className="footer-link">
                <a
                  rel="noopener noreferrer"
                  className="oi-footer-about-link"
                  href="https://www.intuit.com/company/"
                  data-wa-link="ftr-quickbooks-about-intuit"
                  data-ui-object="link"
                  data-refer-ui-access-point="ftr_quickbooks_about_intuit"
                  target="_blank"
                >
                  About Intuit
                </a>
                <div className="bullet-spacing is-available">|</div>
                <a
                  rel="noopener noreferrer"
                  className="oi-footer-about-link break-point"
                  href="https://www.intuit.com/careers/"
                  data-wa-link="ftr-quickbooks-join-our-team"
                  data-ui-object="link"
                  data-refer-ui-access-point="ftr_quickbooks_join_our_team"
                  target="_blank"
                >
                  Join Our Team
                </a>

                <div className="bullet-spacing is-available">|</div>
                <a
                  rel="noopener noreferrer"
                  className="oi-footer-about-link"
                  href="https://www.intuit.com/company/press-room/"
                  data-wa-link="ftr-quickbooks-press"
                  data-ui-object="link"
                  data-refer-ui-access-point="ftr_quickbooks_press"
                  target="_blank"
                >
                  Press
                </a>
              </div>
              <div className="footer-link">
                <div className="bullet-spacing is-available">|</div>
                <a
                  rel="noopener noreferrer"
                  className="oi-footer-about-link"
                  href="https://www.intuit.com/partners/"
                  data-wa-link="ftr-quickbooks-aff-and-partner"
                  data-ui-object="link"
                  data-refer-ui-access-point="ftr_quickbooks_affiliates_and_partners"
                  target="_blank"
                >
                  Affiliates and Partners
                </a>
                <div className="bullet-spacing is-available">|</div>
                <a
                  className="oi-footer-about-link"
                  href="https://www.intuit.com/legal/terms/"
                  data-wa-link="ftr-quickbooks-softw-and-lic"
                  data-ui-object="link"
                  data-refer-ui-access-point="ftr_quickbooks_software_and_licenses"
                >
                  Software and Licenses
                </a>
                <div className="bullet-spacing is-available">|</div>
                <a
                  className="oi-footer-about-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.intuit.com/accessibility/"
                  data-wa-link="ftr-quickbooks-accessibility"
                  data-ui-object="link"
                  data-refer-ui-access-point="ftr_quickbooks_accessibility"
                >
                  Accessibility
                </a>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-8 col-lg-9">
            <div className="row gutter ctext">
              <div className="col-xs-12 col-sm-12 col-lg-9">
                <div className="oi-footer-global-legal">
                  © {new Date().getFullYear()} Intuit Inc. All rights reserved.
                  <br />
                  Intuit, QuickBooks, QB, TurboTax, Proconnect and Credit Karma are registered
                  trademarks of Intuit Inc. Terms and conditions, features, support, pricing, and
                  service options subject to change without notice.
                  <br />
                  <br />
                  By accessing and using this page you agree to the{' '}
                  <a
                    rel="noopener noreferrer"
                    href="https://accounts.intuit.com/terms-of-service"
                    className="terms-data"
                    data-wa-link="ftr-quickbooks-terms-tracking"
                    data-ui-object="link"
                    data-refer-ui-access-point="ftr_quickbooks_terms_and_conditions"
                    target="_blank"
                  >
                    Terms and Conditions.
                  </a>
                </div>
                <div className="oi-footer-global-legal-cookie">
                  <a
                    rel="noopener noreferrer"
                    href="https://security.intuit.com/intuit-cookie-policy/"
                    data-wa-link="ftr-quickbooks-about-cookies"
                    data-ui-object="link"
                    data-refer-ui-access-point="ftr_quickbooks_about_cookies"
                    target="_blank"
                  >
                    About cookies
                  </a>
                  <span className="oifooter-c-divider" />

                  {CpraFunctionalityAvailable && userLocation && userLocation?.country.toUpperCase() === CPRA_Location_Country_Value
                  && userLocation?.state.toUpperCase() === CPRA_Location_State_Value
                 ? (
                    <>
                      <button
                        type="submit"
                        className="do-not-share-personal-info"
                        title="your california privacy rights"
                        onKeyDown={(event) => {
                          event.key === 'Enter' && showCPRAModal();
                        }}
                        onClick={showCPRAModal}
                        data-wa-link="ftr-quickbooks-manage-cookies"
                        data-ui-object="button"
                        data-refer-ui-access-point="ftr_your_california_privacy_rights"
                      >
                        {CPRA_Footer_Text}
                      </button>
                      <img
                        className="privacy-option"
                        loading="lazy"
                        src={privacyoptions}
                        alt="privacy-option"
                        height="18px"
                        width="30px"
                      />
                    </>
                  ) : (
                    <button
                      type="submit"
                      className="manage-cookies-data"
                      title="manage cookies"
                      onKeyDown={(event) => {
                        event.key === 'Enter' && manageCookie();
                      }}
                      onClick={manageCookie}
                      id="manageCookies"
                      data-wa-link="ftr-quickbooks-manage-cookies"
                      data-ui-object="button"
                      data-refer-ui-access-point="ftr_quickbooks_manage_cookies"
                    >
                      Manage cookies
                    </button>
                  )}
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-lg-3">
                <div className="ctext">
                  <div className="footer-global-legalLinks legel-info">
                    <a
                      rel="noopener noreferrer"
                      className="oi-footer-legalLink"
                      href="https://www.intuit.com/legal/"
                      data-wa-link="ftr-corporate-legal"
                      data-ui-object="link"
                      data-refer-ui-access-point="ftr_corporate_legal"
                      target="_blank"
                    >
                      Legal
                    </a>
                    <div className="bullet-spacing">|</div>
                    <a
                      rel="noopener noreferrer"
                      className="oi-footer-legalLink"
                      href="https://www.intuit.com/privacy/"
                      data-wa-link="ftr-corporate-privacy"
                      data-ui-object="link"
                      data-refer-ui-access-point="ftr_corporate_privacy"
                      target="_blank"
                    >
                      Privacy
                    </a>{' '}
                    <div className="bullet-spacing">|</div>
                    <a
                      rel="noopener noreferrer"
                      className="oi-footer-legalLink"
                      href="https://security.intuit.com/"
                      data-wa-link="ftr-corporate-security"
                      data-ui-object="link"
                      data-refer-ui-access-point="ftr_corporate_security"
                      target="_blank"
                    >
                      Security
                    </a>
                  </div>
                  <div>
                    <a
                      className="trust-img-background"
                      rel="noopener noreferrer"
                      href="https://privacy.truste.com/privacy-seal/validation?rid=7fc012b5-d53b-42d8-b980-c4f9091a97e1"
                      data-wa-nav-link="ftr-global-truste"
                      target="_blank"
                    >
                      <img
                        src="https://privacy-policy.truste.com/privacy-seal/seal?rid=7fc012b5-d53b-42d8-b980-c4f9091a97e1"
                        className="truste-img"
                        loading="lazy"
                        height={45}
                        width={141}
                        alt="TRUSTe"
                        data-test-attr="truste-img"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterLink;
