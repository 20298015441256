import { getCookie, getSessionStorage, removeCookie, setCookie } from './CommonUtils';
import { HostedUISignIn } from '../../../assets/pagedata/constant';
import { shopperDataService } from '../../services/shopperDataService';
import { postShopperInfo } from '../../services/iusAuthDataService';

export const Auth = {
  getAuth: () => {
    return getCookie('is_p') === '1';
  },
};

export const HostedSignIn = (id, logToIMWebLogger, isSignUserWithoutPageProperty) => {
  const prodEnv = process.env.REACT_APP_ENVIRONMENT.toLowerCase() === 'production';
  const onShopperSuccess = () => {
    logToIMWebLogger(
      'INFO',
      `Opperation=HostedUISignIn-NewFlowOnShopperSuccess SignUserWithoutPagePropertyFlag=${isSignUserWithoutPageProperty}`,
    );
    const redirectPath = getCookie('redirectPageURI');
    if (getCookie('imprintIdSignin') && getCookie('imprintIdSignin').indexOf('id=')) {
      const tempHostedImprint = getCookie('imprintIdSignin');
      removeCookie('imprintIdSignin');
      window.location.href = `${redirectPath}${tempHostedImprint}`;
    } else {
      window.location.href = redirectPath || '/orderhistory';
    }
  };
  const onSignInShopperSuccess = () => {
    logToIMWebLogger(
      'INFO',
      `Opperation=HostedUISignIn-OldFlowOnSignInShopperSucces SignUserWithoutPagePropertyFlag=${isSignUserWithoutPageProperty}`,
    );
    const redirectPath = getCookie('redirectPageURI');
    if (getCookie('imprintIdSignin') && getCookie('imprintIdSignin').indexOf('id=')) {
      const tempHostedImprint = getCookie('imprintIdSignin');
      removeCookie('imprintIdSignin');
      window.location.href = `${redirectPath}${tempHostedImprint}`;
    } else {
      window.location.href = redirectPath || '/orderhistory';
    }
  };
  const onShignUpShopperSuccess = () => {
    logToIMWebLogger(
      'INFO',
      `Opperation=HostedUISignIn-OldFlowOnSignUpShopperSuccess SignUserWithoutPagePropertyFlag=${isSignUserWithoutPageProperty}`,
    );
    removeCookie('imprintid');
    const redirectPath = getCookie('redirectPageURI');
    if (getCookie('imprintIdSignin') && getCookie('imprintIdSignin').indexOf('id=')) {
      const tempHostedImprint = getCookie('imprintIdSignin');
      removeCookie('imprintIdSignin');
      window.location.href = `${redirectPath}${tempHostedImprint}`;
    } else {
      window.location.href = redirectPath || '/orderhistory';
    }
  };
  if (id === '-1' && getCookie('userIdentifier')) {
    try {
      localStorage.setItem('accountloginId', getCookie('userIdentifier'));
      const shopperJson = {
        UserId: decodeURIComponent(getCookie(prodEnv ? 'qbn.glogin' : 'qbn.ptc.glogin')),
        Email: {
          address: '',
        },
        ShopperId: '',
        GuestShopperId: '',
        AuthId: getCookie('userIdentifier'),
        SessionId: '',
        OrderId: '',
        Phone: '',
      };

      if (isSignUserWithoutPageProperty) {
        logToIMWebLogger(
          'INFO',
          `Opperation=HostedUISignIn-NewFlowShoppercall SignUserWithoutPagePropertyFlag=${isSignUserWithoutPageProperty}`,
        );
        // getting values from cookie
        const imprintIdCopy = getCookie('imprintID_copy');
        let redirectUrl = getCookie('redirecturl');

        // for sign up
        shopperJson.Email.address = decodeURIComponent(
          getCookie(prodEnv ? 'qbn.glogin' : 'qbn.ptc.glogin'),
        );

        if (redirectUrl && redirectUrl.includes('_fromipdconf')) {
          if (imprintIdCopy) {
            shopperJson.imprintId= imprintIdCopy
            removeCookie('imprintID_copy');
          }
          // for sign up functionality
          if (redirectUrl.includes('_signup')) {
            redirectUrl = redirectUrl.replace('_signup', '');
          }
          redirectUrl = redirectUrl.replace('_fromipdconf', '');
          setCookie('redirecturl', redirectUrl);
          // enable if coming form confirmation page
          if (localStorage.getItem('receiptLnk') === '1') {
            localStorage.removeItem('receiptLnk');
            if (getCookie('_lastOrder')) {
              shopperJson.OrderId = getCookie('_lastOrder')?.orderData.OrderNumber;
            }
          }
        } 

          const imprintId = getCookie('imprintid');
          if (imprintId) {
            shopperJson.imprintId = imprintId;
            removeCookie('imprintid');
          }

        // for capturing imprintIds if bundle products
        if (getSessionStorage('Bundle_Imprints')?.value) {
          shopperJson.bundle_imprintIds = JSON.parse(getSessionStorage('Bundle_Imprints').value);
        }

        // calling shopper api
        shopperDataService
          .updateShopper({ data: shopperJson })
          .then(onShopperSuccess)
          .catch((error) => {
            logToIMWebLogger(
              'ERROR',
              `Opperation=HostedUISignIn-NewFlowShopperError SignUserWithoutPagePropertyFlag=${isSignUserWithoutPageProperty} OperationDetails=${error}`,
            );
          });
      } else {
        let urlParams = {};
        urlParams.imprintid = getCookie('imprintid') ? getCookie('imprintid') : '';
        if (
          localStorage.getItem('accountloginMethod') &&
          localStorage.getItem('accountloginMethod').toLowerCase() === 'account_creation'
        ) {
          logToIMWebLogger(
            'INFO',
            `Opperation=HostedUISignIn-OldFlowShoppercallSignUp SignUserWithoutPagePropertyFlag=${isSignUserWithoutPageProperty}`,
          );
          localStorage.removeItem('accountloginMethod');
          let imprint = getCookie('imprintid') ? getCookie('imprintid') : '';
          const imprintIdCopy = getCookie('imprintID_copy');
          let redirectUrl = getCookie('redirecturl');
          shopperJson.Email.address = decodeURIComponent(
            getCookie(prodEnv ? 'qbn.glogin' : 'qbn.ptc.glogin'),
          );
          if (!!redirectUrl && redirectUrl.indexOf('_fromipdconf') > -1) {
            if (typeof imprintIdCopy !== 'undefined') {
              imprint = imprintIdCopy;
              removeCookie('imprintID_copy');
            }
            if (redirectUrl.indexOf('_signup') > -1) {
              redirectUrl = redirectUrl.replace('_signup', '');
            }
            redirectUrl = redirectUrl.replace('_fromipdconf', '');
            setCookie('redirecturl', redirectUrl);
            const lastOrder = getCookie('_lastOrder');
            if (lastOrder) {
              shopperJson.OrderId = lastOrder.orderData.OrderNumber;
              urlParams = {
                imprintid: imprint,
                orderId: shopperJson.OrderId,
                from: 'confirmationPage',
              };
            }
          } else {
            urlParams.imprintid = imprint;
            if (!!redirectUrl && redirectUrl.indexOf('_fromipdsum') > -1) {
              urlParams.from = 'summaryPage';
              redirectUrl = redirectUrl.replace('_fromipdsum', '');
              setCookie('redirecturl', redirectUrl);
            }
          }
          postShopperInfo(shopperJson, urlParams)
            .then(onShignUpShopperSuccess)
            .catch((error) => {
              logToIMWebLogger(
                'ERROR',
                `Opperation=HostedUISignIn-OldFlowPostShopperError SignUserWithoutPagePropertyFlag=${isSignUserWithoutPageProperty} OperationDetails=${error}`,
              );
            });
        } else if (
          localStorage.getItem('accountloginMethod') &&
          localStorage.getItem('accountloginMethod').toLowerCase() === 'account_sign_in'
        ) {
          logToIMWebLogger(
            'INFO',
            `Opperation=HostedUISignIn-OldFlowShoppercallSignIn SignUserWithoutPagePropertyFlag=${isSignUserWithoutPageProperty}`,
          );
          localStorage.removeItem('accountloginMethod');
          // this should be refactered
          const objURL = {};
          let imprint = '';
          // eslint-disable-next-line no-unused-vars
          let reorderFlow = '0';
          if (window.location.hash.indexOf('?') >= 0) {
            const str = window.location.hash.substring(
              window.location.hash.indexOf('?'),
              window.location.hash.length,
            );
            str.toLowerCase().replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
              if ($1 === 'reorder' && $2 === '1') {
                reorderFlow = '1';
              }
              objURL[$1] = $3;
            });
          }
          if (
            objURL &&
            (objURL.new_imprint_id ||
              objURL.imprintid ||
              objURL.imprint_id ||
              objURL.imprint_instance_id)
          ) {
            imprint =
              objURL.new_imprint_id ||
              objURL.imprintid ||
              objURL.imprint_id ||
              objURL.imprint_instance_id;
            if (reorderFlow === '1') {
              if (typeof getCookie('imprintid') !== 'undefined') {
                shopperJson.imprintId = getCookie('imprintid');
                removeCookie('imprintid');
              }
            } else if (imprint !== -1) shopperJson.imprintId = imprint;
          } else {
            const redirecturl = getCookie('redirecturl');
            if (
              !!redirecturl &&
              redirecturl.indexOf('_fromipdconf') > -1 &&
              window.location.pathname === '/confirmation'
            ) {
              if (typeof getCookie('imprintID_copy') !== 'undefined') {
                shopperJson.imprintId = getCookie('imprintID_copy');
                removeCookie('imprintID_copy');
              }
            } else if (typeof getCookie('imprintid') !== 'undefined') {
              shopperJson.imprintId = getCookie('imprintid');
              removeCookie('imprintid');
            }
          }
          /*
           * Sign-in or Sign up @ receipt page: set widgetConfig.receiptLnk to true.
           */
          const receiptLnk = localStorage.getItem('receiptLnk') === '1';
          localStorage.removeItem('receiptLnk');
          if (receiptLnk && getCookie('_lastOrder')) {
            shopperJson.OrderId = getCookie('_lastOrder').orderData.OrderNumber;
          }
          /*
           * Sign-in @ value pack logo upload: pass all imprints associated with the bundle and update shopper for all.
           */
          const bundle_Imprints = getSessionStorage('Bundle_Imprints')
            ? getSessionStorage('Bundle_Imprints').value
              ? JSON.parse(getSessionStorage('Bundle_Imprints').value)
              : ''
            : '';
          if (bundle_Imprints) {
            shopperJson.bundle_imprintIds = bundle_Imprints;
          }
          shopperDataService
            .updateShopper({ data: shopperJson })
            .then(onSignInShopperSuccess)
            .catch((error) => {
              logToIMWebLogger(
                'ERROR',
                `Opperation=HostedUISignIn-oldFlowUpdateShopperError SignUserWithoutPagePropertyFlag=${isSignUserWithoutPageProperty} OperationDetails=${error}`,
              );
            });
        } else if (
          localStorage.getItem('accountloginMethod') &&
          localStorage.getItem('accountloginMethod').toLowerCase() === 'account_recovery'
        ) {
          logToIMWebLogger(
            'INFO',
            `Opperation=HostedUISignIn-OldFlowAccountRecovery SignUserWithoutPagePropertyFlag=${isSignUserWithoutPageProperty}`,
          );
          localStorage.removeItem('accountloginMethod');
          localStorage.removeItem(HostedUISignIn.customizationReducer);
          localStorage.removeItem(HostedUISignIn.currentStep);
          const track = getCookie('track');
          const originalTrack = getCookie('originaltrack');
          window.location.href = `/common/idmanager/LoginAfterPwdRecovery.aspx?iusacctrec=1&originaltrack=${originalTrack}&track=${track}`;
        } else {
          logToIMWebLogger(
            'ERROR',
            `Opperation=HostedUISignIn-OldFlowAccountRecoveryError OperationDetails=pageProperties not matched - ${
              (getCookie('pageProperties'), localStorage.getItem('accountloginMethod'))
            }`,
          );
          localStorage.removeItem('accountloginMethod');
        }
      }
    } catch (error) {
      logToIMWebLogger('ERROR', `Opperation=HostedUISignIn-OldFlowError OperationDetails=${error}`);
    }
  }
};
