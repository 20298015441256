// eslint-disable-next-line require-jsdoc
import React, { Suspense, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import HeaderPage from '../Header/HeaderPage';
import imRoutes from '../../routes/imroutes.jsx';
import PageData from '../../../assets/pagedata/product-route-page-info.jsx';
import FooterPage from '../Footer/FooterPage';
import FabComponentPage from './FabComponentPage';
import '../../../stylesheets/main.scss';
import ScrollToTop from '../Util/ScrollToTop';
import NotFoundPage from '../404/NoteFoundPage';
import withLogAnalytics from '../Util/withLogAnalytics';
import {
  CreateMeta,
  getCookie,
  setSessionStorage,
  getSessionStorage,
  isIE11Browser,
  trackClickEvent,
} from '../Util/CommonUtils.jsx';
import { SagaEvent,isSignUserWithoutPagePropertyFlag } from '../../../assets/pagedata/constant';
import {
  getClientConfig,
} from '../../services/commonDataService.js';
import Loader from '../Common/Loader';
import { sendData, lproutechange } from '../Util/NavigationUtil';
import { FreeShippingPromo } from '../Common/FreeShippingPromo';
import { UnAuthenticatedRoute } from '../Auth/UnAuthenticatedRoute';
import Ie11Banner from '../Common/Ie11Banner';
import IeMigrationModal from '../Common/IeMigrationModal';
import { HostedSignIn } from '../Util/AuthUtil';

// eslint-disable-next-line require-jsdoc
const MainComponentPage = (props) => {
  /* istanbul ignore next line */
  window.wa = window.wa || {};
  /* istanbul ignore next line */
  const history = useHistory();
  /* istanbul ignore next line */
  const [metaData, setMeta] = useState(CreateMeta(history.location));
  const mainReducer = useSelector((state) => state.mainReducer);
  const [IeMigrationPopUp, setIeMigrationPopUp] = useState(false);
  /* istanbul ignore next line */
  const dispatch = useDispatch();
  /* istanbul ignore next line */
  const appRoutes = imRoutes;

  const { loading, pageData, clientConfigs } = mainReducer;
  // check config flag for new voucher value pack
  const isVoucherValuePackPromoEnabled = !!(
    clientConfigs &&
    clientConfigs.intuitmarketFlagsisVoucherValuePackPromoEnabled.toLowerCase() === 'true'
  );

  const isChatEnabled = !!(
    clientConfigs && clientConfigs.intuitmarketFlagsisChatEnabled.toLowerCase() === 'true'
  );

  const isTrackingEnabled =
    clientConfigs?.TrackStarenabled && clientConfigs?.TrackStarenabled.toLowerCase() === 'true';
  const [isHeaderAndFooterHidden, setHideHeaderAndFooter] = useState(false);
  const markUsertTimeForRoute = (location) => {
    let params;
    if (location.pathname.indexOf('@') !== -1) {
      params = location.pathname.substring(0, location.pathname.indexOf('@'));
    }

    // eslint-disable-next-line prefer-destructuring
    if (params && params.length > 1) params = params[0];
    else params = location.pathname;

    props.markUserTime(`route_${params ? params.substring(1) : ''}`);
  };

  const hideHeaderFooter = (location) => {
    if (location.pathname === '/printreceipt' || location.pathname === '/file-upload-disclaimer') {
      setHideHeaderAndFooter(true);
    } else {
      setHideHeaderAndFooter(false);
    }
  };

  const displayIeMigMsgBanr = (location) => {
    if (
      !loading &&
      location?.pathname !== '/help/migrate-from-ie11' &&
      isIE11Browser() &&
      location?.pathname !== '/reorder'
    ) {
      return true;
    }
    return false;
  };

  const setPageNameForAnalytics = (location) => {
    const pData = {
      pagePath: location.pathname,
    };
    if (typeof window.wa.trackPage !== 'undefined') window.wa.trackPage(pData);
  };

  const processUrlSourceCode = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let sourceCodeApplied = urlParams.get('src');

    if (sourceCodeApplied === undefined || sourceCodeApplied === '' || sourceCodeApplied === null)
      sourceCodeApplied = getCookie('source');

    if (sourceCodeApplied !== undefined && sourceCodeApplied !== '' && sourceCodeApplied !== null)
      setSessionStorage('appliedThrUrlOffer', sourceCodeApplied);

    const currentOffer = getSessionStorage('currentoffer')
      ? getSessionStorage('currentoffer').value
      : '';

    if (!currentOffer && sourceCodeApplied) setSessionStorage('currentoffer', sourceCodeApplied);
  };
  React.useEffect(() => {
    if (isTrackingEnabled) {
      window.addEventListener('click', trackClickEvent);
      return () => {
        window.removeEventListener('click', trackClickEvent);
      };
    }
  });

  React.useEffect(() => {
    sendData();
    // Marke the time on the first load
    markUsertTimeForRoute(history.location);
    // Process source codes applied through url
    processUrlSourceCode();
    // Send the new page info to live person chat - fix for cobrowse tool
    if (isChatEnabled) {
      lproutechange();
    }
    history.listen((location) => {
      sendData();
      markUsertTimeForRoute(location);
      setMeta(CreateMeta(location));
      hideHeaderFooter(location);
      setPageNameForAnalytics(location);
      // Send the new page info to live person chat - fix for cobrowse tool
      if (isChatEnabled) {
        lproutechange();
      }
      displayIeMigMsgBanr(location);
      try {
        if (isTrackingEnabled) {
          window.intuit.tracking.ecs.webAnalytics.trackPage({
            scope_area: 'storefront_and_marketplace',
            screen:
              window.location.pathname === '/' ? 'homepage' : window.location.pathname.substr(1),
            action: 'viewed',
            object: 'screen',
            ui_action: 'viewed',
            ui_object: 'screen',
            region: 'US',
          });
        }
      } catch (error) {
        props.logToIMWebLogger(
          'ERROR',
          `Flow=Eventbus Opperation=trackpage Component=MainComponent.jsx Status=error Message=${error}`,
        );
      }
    });

    hideHeaderFooter(history.location);
    const refURLParam = new URLSearchParams(window.location.search);
    const id = refURLParam.get('ref_');
    //keeping logic as flag based
    if (id === '-1' && getCookie('userIdentifier')) {
      getClientConfig('POST',[isSignUserWithoutPagePropertyFlag]).then((res) => {
        HostedSignIn(id, props.logToIMWebLogger,res[isSignUserWithoutPagePropertyFlag]==="True"?true:false);
      });
      
    }
    // will get used once above sign in/sign up logic tested
    // HostedSignIn(id, props.logToIMWebLogger, isSignUserWithoutPageProperty);

    const getClientConfigs = async () => {
      const getConfigPayload = [
        'FreeShipping.isPromoActive',
        'FreeShipping.isSecureFshPromoActive',
        'FreeShipping.promoDate',
        'FreeShipping.secureFshPromoDate',
        'IUS.IUSAccountManager',
        'intuitmarketFlags.isEFTImprintAddressVerification',
        'IUS.IUSAccountRecoveryEndPoint',
        'intuitmarketFlags.isChecksUpgradeFlowActive',
        'intuitmarketFlags.isChecksReorderFlowActive',
        'intuitmarketFlags.isICPMigrationEnabled',
        'ProductUnavailable.TaxFormInclusiveFromDate',
        'ProductUnavailable.TaxFormExclusiveToDate',
        'Giact.CombinedFraudDetectCallEnabled',
        'Giact.FraudDetectCallEnabled',
        'QuestServices.CCTokenizationUrl',
        'QuestServices.CVVTokenizationUrl',
        'QuestServices.WalletCVVTimeout',
        'QuestServices.WalletCCTimeout',
        'Wallet.isClientCall',
        'QuestServices.TokenizationAutHeader',
        'Asset.IntuitWebAppSecret',
        'intuitmarketFlags.isSDTforSavedCCEnabled',
        'Wallet.lifetime',
        'Wallet.Tokens',
        'Wallet.DurableWallet',
        'Wallet.TokentoToken',
        'Asset.IntuitWebAppId',
        'intuitmarketFlags.isVoucherValuePackPromoEnabled',
        'ProductOutOfStock.OutOfStockMsg',
        'ProductOutOfStock.OutOfStockPfid',
        'ProductOutOfStock.OutOfStockBaseSku',
        'intuitmarketFlags.isShippingDelayed',
        'StockChangeText.ForFolders',
        'ProductOutOfStock.isOutOfStock',
        'intuitmarketFlags.taxExemptHoldAutomation',
        'ProductOutOfStock.isOutOfStockPhase2',
        'intuitmarketFlags.isHandleSaveOrderIssue',
        'intuitmarketFlags.isProductEnvelopeRestricted',
        'intuitmarketFlags.isCpraEnabled',
        'ProductOutOfStock.isOutOfStockPhase3',
        'ProductOutOfStock.isOutOfStockPhase4',
        'intuitmarketFlags.isChatEnabled',
        'intuitmarketFlags.billingAddressValidation',
        'intuitmarketFlags.securityGreen',
        'TrackStar.enabled',
        'intuitmarketFlags.FCRA',
      ];

      dispatch({
        type: SagaEvent.INITIALIZE_INITIAL_PAGE_LOAD,
        data: { payload: getConfigPayload },
      });
    };

    /* istanbul ignore next line */
    if (!(id === '-1' && getCookie('userIdentifier'))) {
      getClientConfigs();
    }
    if(getSessionStorage('CDNFallback')?.value){
      props.logToIMWebLogger(
        'ERROR',
        `Operation=CDNStatus Status=Offline`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, isTrackingEnabled]);

  React.useEffect(() => {
    const IePopupShown = getSessionStorage('isIePopupShown')?.value;
    if (!IePopupShown) {
      console.log('Display IE modal');
      setIeMigrationPopUp(true);
      setSessionStorage('isIePopupShown', true);
    }
  }, []);

  const handleIeMigrationModalClose = () => {
    setIeMigrationPopUp(false);
  };

  const onLoad = () => {
    // Logging the path name. Temporary to check if any uppercase path name is present in code
    if (/[A-Z]/.test(window.location.pathname)) {
      props.logToIMWebLogger(
        'INFO',
        `Opperation=urlLowerCaseCheck value=${window.location.pathname}`,
      );
    }
    return true;
  };
  const LoaderOff = ['/']

  /* istanbul ignore next line */
  const renderHtml = loading && !LoaderOff.includes(window.location.pathname) ? (
    <div style={{ height: '80vh' }}>
      <Loader show={loading} size={80} loadingText="Loading.." />
    </div>
  ) : (
    appRoutes.map((route, key) => {
      if (route.redirect) {
        return <Redirect key={key} from={route.path} to={route.to} />;
      }

      if (route.redirectWhenLoggedIn && pageData.login) {
        return <Redirect key={key} from={route.path} to={route.to} />;
      }
      if (route.redirectExternal) {
        return (
          <Route
            exact={route.exactMatch}
            path={route.path}
            key={key}
            render={() => {
              window.location.href = route.redirectExternal;
            }}
          />
        );
      }
      if (route.conditionalLoad && !isVoucherValuePackPromoEnabled) {
        return null;
      }
      if (route.props && route.props.authenticatedRoute && !pageData.login) {
        return (
          <Route
            exact={route.exactMatch}
            path={route.path}
            key={key}
            render={(routeProps) =>
              React.createElement(UnAuthenticatedRoute, {
                ...routeProps,
                ...props,
                ...route.props,
                childrenRoutes: route.children,
                location: window.location,
                pageData: PageData[route.contentVariable],
                contentVariable: route.contentVariable,
                onLoad: onLoad(),
              })
            }
          />
        );
      }
      return (
        <Route
          exact={route.exactMatch}
          path={route.path}
          key={key}
          render={(routeProps) =>
            React.createElement(route.component, {
              ...routeProps,
              ...props,
              ...route.props,
              childrenRoutes: route.children,
              location: window.location,
              pageData: PageData[route.contentVariable],
              contentVariable: route.contentVariable,
              onLoad: onLoad(),
            })
          }
        />
      );
    })
  );

  /* istanbul ignore next line */
  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={metaData.meta.description} />
          {metaData.meta.robots && <meta name="robots" content={metaData.meta.robots} />}
          <title>{metaData.title}</title>
          <link rel="canonical" href={metaData.canonical} />
          <script type="application/ld+json">{metaData.script}</script>
        </Helmet>
        {!isHeaderAndFooterHidden && <HeaderPage {...props} />}
        {displayIeMigMsgBanr(window.location) && <Ie11Banner />}
        {displayIeMigMsgBanr(window.location) && IeMigrationPopUp && (
          <IeMigrationModal
            openIeMigrationModal={IeMigrationPopUp}
            ieMigrationModalClose={handleIeMigrationModalClose}
          />
        )}
        <ScrollToTop />
        <Suspense fallback={<Loader show={true} size={80} loadingText="Loading.." />}>
          <Switch>
            {renderHtml}
            <Route
              status={404}
              render={(routeProps) =>
                React.createElement(NotFoundPage, {
                  ...routeProps,
                  ...props,
                })
              }
            />
          </Switch>
        <FabComponentPage {...props} />
        <FreeShippingPromo />
        {!isHeaderAndFooterHidden && <FooterPage {...props} />}
        </Suspense>
      </div>
    </>
  );
};

MainComponentPage.propTypes = {
  logToIMWebLogger: PropTypes.func.isRequired,
  markUserTime: PropTypes.func.isRequired,
  logToAnalytics: PropTypes.func.isRequired,
};

export default withLogAnalytics(MainComponentPage);